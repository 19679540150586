import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { BsGithub } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { FaAppStoreIos } from 'react-icons/fa';
import { IoLogoGooglePlaystore } from 'react-icons/io5';

function ProjectCards(props) {
	const {
		company,
		title,
		project,
		subProject,
		description,
		features,
		technologies,
		tools,
		achievements,
		imgPath,
		ghLinkFE,
		ghLinkBE,
		appleStore,
		chPlay,
		linkDemo,
		time
	} = props;

	const renderItem = (item, index) => {
		if (typeof item !== 'string') {
			if (Array.isArray(item) && item.length) {
				return (
					<li key={`${item}-${index}`} style={{ fontWeight: 'bold', fontSize: '14px' }}>
						{index === 0 ? 'Frontend (FE):' : index === 1 ? 'Backend (BE):' : 'Data (DB):'}
						<ul>{item.map(renderItem)}</ul>
					</li>
				);
			} else {
				return Object.keys(item).map((value, i) => (
					<li key={`${item}-${index}-${i}`} style={{ fontWeight: 'bold', fontSize: '14px' }}>
						{value}
						<span style={{ fontWeight: 'normal' }}> {item[value]}</span>
					</li>
				));
			}
		}
		return (
			<li key={`${item}-${index}`} style={{ fontWeight: 'normal', fontSize: '14px' }}>
				{item}
			</li>
		);
	};
	return (
		<Card className="project-card-view">
			<Card.Img variant="top" src={imgPath} alt="card-img" />
			<Card.Body className="d-flex flex-column gap-2">
				<div className={'d-flex flex-column flex-md-row justify-content-between'}>
					<Card.Title>{company}</Card.Title>
					<Card.Text className="fst-italic">{time}</Card.Text>
				</div>
				<Card.Subtitle style={{ textAlign: 'left', fontWeight: 'bold' }}>
					{title} - {project}
					{subProject && (
						<span className="fst-italic" style={{ fontWeight: 'normal' }}>
							{' '}
							- {subProject}
						</span>
					)}
				</Card.Subtitle>
				{description && (
					<Card.Text style={{ textAlign: 'left', fontWeight: 'normal', marginBottom: 0 }}>{description}</Card.Text>
				)}
				<Card.Text style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 0 }}>
					Notable Features and Functionalities:
					<ul>{features.map(renderItem)}</ul>
				</Card.Text>
				<Card.Text style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 0, marginTop: '-1rem' }}>
					Technologies and Libraries Used:
					<ul>{technologies.map(renderItem)}</ul>
				</Card.Text>
				{tools && (
					<Card.Text style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 0, marginTop: '-1rem' }}>
						Project Management Tools:
						<span style={{ fontWeight: 'normal' }}> {tools}</span>
					</Card.Text>
				)}
				{achievements && (
					<Card.Text style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 0, marginTop: '-1rem' }}>
						Achievements:
						<ul>{achievements.map(renderItem)}</ul>
					</Card.Text>
				)}
			</Card.Body>
			<Card.Footer className="d-flex gap-2 justify-content-center">
				{appleStore && (
					<Button variant="primary" href={appleStore} target="_blank">
						<FaAppStoreIos /> &nbsp; Apple Store
					</Button>
				)}
				{chPlay && (
					<Button variant="primary" href={chPlay} target="_blank">
						<IoLogoGooglePlaystore /> &nbsp; CH Play
					</Button>
				)}
				{ghLinkFE && (
					<Button variant="primary" href={ghLinkFE} target="_blank">
						<BsGithub /> GitHub FE
					</Button>
				)}
				{ghLinkBE && (
					<Button variant="primary" href={ghLinkBE} target="_blank">
						<BsGithub /> GitHub BE
					</Button>
				)}
				{linkDemo && (
					<Button variant="primary" href={linkDemo} target="_blank">
						<CgWebsite /> Website
					</Button>
				)}
			</Card.Footer>
		</Card>
	);
}
export default ProjectCards;
