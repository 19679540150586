import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SiVisualstudiocode, SiPostman, SiVercel, SiAndroidstudio, SiXcode } from 'react-icons/si';
import { FaGit, FaJira } from 'react-icons/fa';

function Toolstack() {
	return (
		<div>
			<h1 className="project-heading text-center">
				<strong className="purple">Tools</strong> I use
			</h1>
			<Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
				<Col xs={4} md={2} className="tech-icons">
					<FaGit />
				</Col>
				<Col xs={4} md={2} className="tech-icons">
					<SiVisualstudiocode />
				</Col>
				<Col xs={4} md={2} className="tech-icons">
					<SiPostman />
				</Col>
				<Col xs={4} md={2} className="tech-icons">
					<FaJira />
				</Col>
				<Col xs={4} md={2} className="tech-icons">
					<SiVercel />
				</Col>
				<Col xs={4} md={2} className="tech-icons">
					<SiAndroidstudio />
				</Col>
				<Col xs={4} md={2} className="tech-icons">
					<SiXcode />
				</Col>
			</Row>
		</div>
	);
}

export default Toolstack;
